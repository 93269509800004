import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://ec36bd8d39487f50929d8e6f431e184d@o4507032620433408.ingest.de.sentry.io/4508341377761360",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});